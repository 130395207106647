<template>
  <div class="question main__wrapper">
    <div class="question__wrapper container">
      <div class="block__back" @click="goToHome">
        <svg-icon name="arrow-back" />
        <h2 class="title-div">{{ $t('modals.back') }}</h2>
      </div>

      <h1 class="question__title">
        {{ $t(`faq.${currentRouteParam}.title`) }}
      </h1>

      <div class="question__content title-h5">
        <div class="question__text">
          <div
            v-for="text in currentContent['content']"
            class="question__p"
            v-html="$t(`faq.${currentRouteParam}.description.${text}`)"
          ></div>
          <div class="question__p">
            <ul class="question__list">
              <li
                v-for="list in currentContent['list']"
                v-html="$t(`faq.${currentRouteParam}.description.${list}`)"
              ></li>
            </ul>
          </div>

          <div
            v-for="additionalItem in currentContent['additional_content']"
            class="question__p"
            v-html="
              $t(`faq.${currentRouteParam}.description.${additionalItem}`)
            "
          ></div>
        </div>

        <ul class="question__block">
          <h3 class="question__block_title">
            {{ $t(`faq.related_questions`) }}
          </h3>
          <router-link
            v-for="item in faqPages"
            :key="item.path"
            :to="{
              path: `${item.path}`,
            }"
            class="question__block-link"
            :class="{ active: item.path === currentRouteParam }"
          >
            <li>
              {{ $t(`faq.${item.title}`) }}
            </li>
          </router-link>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      faqPages: [
        {
          path: 'hotel-categorization-procedure',
          title: 'hotel-categorization-procedure.title',
          content: [
            'step_1',
            'step_2',
            'step_3',
            'step_4',
            'step_5',
            'step_6',
            'normative_base.title',
          ],
          list: [
            'normative_base.law_tourism',
            'normative_base.resolution_803',
            'normative_base.resolution_470',
            'normative_base.order_858',
            'national_standards.dstu_4268',
            'national_standards.dstu_4269',
            'national_standards.dstu_4527',
            'national_standards.dstu_4094',
            'national_standards.dbn_v2_2_9',
            'national_standards.dstu_4281',
          ],
        },
        {
          path: 'tour-operator-license-procedure',
          title: 'tour-operator-license-procedure.title',
          content: [
            'step_1',
            'step_1_1',
            'step_1_2',
            'step_1_3',
            'step_1_4',
            'step_1_5',
            'step_1_6',
            'step_2',
            'step_3',
            'step_4',
            'normative_base.title',
          ],
          list: [
            'normative_base.law_tourism',
            'normative_base.law_licensing',
            'normative_base.resolution_609',
            'normative_base.license_conditions',
          ],
        },
        {
          path: 'get-kep',
          title: 'get-kep.title',
          content: ['section_0', 'section_1', 'section_2', 'list_title'],
          list: ['list_0', 'list_1', 'list_2', 'list_3', 'list_4'],
        },
        {
          path: 'get-kep-fiscal',
          title: 'get-kep-fiscal.title',
          content: ['section_0', 'section_1'],
        },
        {
          path: 'e-signature',
          title: 'e-signature.title',
          content: ['section_0', 'section_1', 'list_title'],
          list: ['list_0', 'list_1', 'list_2', 'list_3', 'list_4'],
          additional_content: ['section_1'],
        },
        {
          path: 'who-is-user',
          content: ['section_0'],
          title: 'who-is-user.title',
          list: ['list_0', 'list_1', 'list_2', 'list_3', 'list_4'],
        },
        {
          path: 'who-can-enter',
          content: ['section_0'],
          title: 'who-can-enter.title',
        },
        {
          path: 'system-secure',
          content: ['section_0', 'section_1', 'section_2'],
          title: 'system-secure.title',
        },
        {
          path: 'what-data-published',
          content: ['section_0', 'section_1', 'section_2'],
          title: 'what-data-published.title',
        },
      ],
    };
  },

  computed: {
    currentContent() {
      return this.faqPages.find((el) => el.path === this.currentRouteParam);
    },

    currentRouteParam() {
      return this.$route.params.id;
    },
  },

  methods: {
    goToHome() {
      this.$router.push({ name: 'questions' });
    },
  },
};
</script>

<style lang="sass" scoped>
.question__block-link.active
  text-decoration: underline
  cursor: default
</style>
